<template>
  <div>
    <v-container>
      <div class="mt-4">
        <v-data-table
            :headers="headers"
            :items="tickets"
            :loading="loading"
            class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title>Reservas</v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                  v-model="newTicket"
                  max-width="80%"
              >
                <new-reservation @cancel="closeModal" :date="getEventDate(schedule.schedule).date" :hour="getEventDate(schedule.schedule).hour"  :schedule_id="$route.params.id"></new-reservation>

                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="primary"
                      dark
                      class="ma-2"
                      v-bind="attrs"
                      v-on="on"
                      @click="newTicket=true"
                  >
                    Nueva reserva
                  </v-btn>
                </template>
              </v-dialog>
            </v-toolbar>
          </template>


          <template v-slot:item.user_id="{item}">
            {{ item.user_id == 1 ? 'Sin Usuario': item.user.email}}
          </template>
          <template v-slot:item.tickets="{item}">
            <v-row v-for="ticket in item.tickets" :key="ticket.id">
             <v-col cols="6">{{ticket.code}}</v-col>
             <v-col cols="6">{{ticket.used ? 'Usado': 'Sin Utilizar'}}</v-col>
            </v-row>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>

<script>
import {AmphiSchedules} from "@/services/admin.service";
import NewReservation from "@/views/admin/NewReservation";

export default {
  name: "Tickets",
  components: {NewReservation},
  data(){
    return {
      headers: [

        {text: 'ID', value:'id'},
        {text: 'Paycomet ID', value:'receipt'},
        {text: 'Usuario', value:'user_id'},
        {text: 'Mesa', value:'amphi_table.name'},
        {text: 'Tikcets', value:'tickets'},
      ],
      tickets: [],
      loading: true,
      newTicket: false,
      schedule: null,
    }
  },
  mounted() {
    this.$store.commit('setLoading', false)
    this.getReservations()
  },
  methods:{
    getReservations(){
      AmphiSchedules().reservations(this.$route.params.id).then(
          res => {
            this.tickets = res.data.data
            this.loading = false
          }
      )
      AmphiSchedules().retrieve(this.$route.params.id).then(
         res =>{
           this.schedule = res.data.data
         }
      )
    },
    getEventDate(schedule) {
      let date = schedule.split('T')
      const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
      let eventDate = new Date(date[0])
      return {
        date: eventDate.toLocaleString('es-es', options),
        hour: date[1].substr(0, 5)
      }
    },
    closeModal(){
      this.newTicket = false
      this.loading= true
      this.getReservations()
    }
  }
}
</script>

<style scoped>

</style>