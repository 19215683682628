<template>
  <div>
    <v-card>
      <v-card-title>
        Nueva Reserva
      </v-card-title>
      <v-card-text class="text-uppercase text-center">Selecciona tus asientos</v-card-text>

      <v-container>
        <v-row>
          <v-col cols="12" md="8">
            <div v-if="selectedTable">
              <amphitheater :selected="selectedTable" @change="value => selectedTable = value" :active="selectTables" width="90%" height="100%"></amphitheater>
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <v-row>
              <v-col cols="12" md="9">
                <v-text-field
                    solo
                    label="Email"
                    prepend-icon="mdi-email"
                    v-model="email"
                ></v-text-field>
              </v-col>
              <v-col  cols="12" md="3">
                <v-select
                    solo
                    :items="person"
                    v-model="people"
                    @change="getTables"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <h3>Datos de la reserva</h3>
            </v-row>
            <v-row align="center">
              <v-col cols="12" md="6" class="pa-0">
                <v-card-text class="font-weight-bold text-uppercase">Fecha:</v-card-text>
              </v-col>
              <v-col  cols="12" md="6"  class="pa-0">
                <v-card-subtitle>{{ date }}</v-card-subtitle>
              </v-col>
              <v-col cols="12" md="6"  class="pa-0">
                <v-card-text class="font-weight-bold text-uppercase">Horario:</v-card-text>
              </v-col>
              <v-col  cols="12" md="6"  class="pa-0">
                <v-card-subtitle >{{ hour }}</v-card-subtitle>
              </v-col>
              <v-col cols="12" md="6"  class="pa-0">
                <v-card-text class="font-weight-bold text-uppercase">Capacidad de la Mesa:</v-card-text>
              </v-col>
              <v-col  cols="12" md="6"  class="pa-0">
                <v-card-subtitle>{{ tableCapacity }}</v-card-subtitle>
              </v-col>
              <v-col cols="12" md="6"  class="pa-0">
                <v-card-text class="font-weight-bold text-uppercase">Mesa:</v-card-text>
              </v-col>
              <v-col  cols="12" md="6"  class="pa-0">
                <v-card-subtitle>{{ selectedTable }}</v-card-subtitle>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>



      <v-card-actions>
        <v-btn
            color="primary"
            @click="generateTickets"
        >
          Continue
        </v-btn>

        <v-btn text
        @click="cancel">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Amphitheater from "@/components/Amphitheater";
import {AmphiEvents as adminAmphi} from "@/services/admin.service";
import {AmphiEvents} from "@/services/api.js";
import AuthService from "@/services/auth.service";
export default {
  name: "NewReservation",
  components: {Amphitheater},
  props:['schedule_id','date' ,'hour'],
  data(){
    return {
      selectedTable: null,
      selectTables: [],
      tableCapacity: null,
      steps: 1,
      people: 5,
      tables: [],
      person: [...Array(8).keys()],
      email: null
    }
  },
  mounted() {
    this.getTables()
  },
  methods:{
    getTables(){
      this.selectedTable = null
      AmphiEvents(AuthService.getUserToken(), '').getTables(this.schedule_id, {amount: this.people})
          .then(response =>
          {
            let tables = response.data.data
            let arrTables = this.tables
            this.selectTables = tables.map(function(elem) {
              let tableObject = {
                capacity: elem.capacity,
                value: elem.name,
                id: elem.id
              }
              arrTables.push(tableObject)
              return elem.name;
            });
            this.selectedTable = this.selectTables[0]
          })

    },
    getTable(){
      return this.tables.find( elem => elem.value === this.selectedTable)
    },
    generateTickets(){
      let table = this.getTable()
      adminAmphi().create_admin(
          {
            amphi_table_id: table.id,
            event_schedule_id: this.schedule_id,
            email: this.email
          }
      ).then(
          this.cancel
      )
    },
    cancel(){
      this.selectedTable= null
      this.selectTables= []
      this.tableCapacity= null
      this.steps= 1
      this.people= 5
      this.tables= []
      this.person= [...Array(10).keys()]
      this.email= null
      this.$emit('cancel')
    }
  },
  watch:{
    selectedTable(newval){
      this.tableCapacity = this.tables.find( elem => elem.value === newval).capacity
    }
  },
}
</script>

<style scoped>

</style>