var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('div',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tickets,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Reservas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.newTicket=true}}},'v-btn',attrs,false),on),[_vm._v(" Nueva reserva ")])]}}]),model:{value:(_vm.newTicket),callback:function ($$v) {_vm.newTicket=$$v},expression:"newTicket"}},[_c('new-reservation',{attrs:{"date":_vm.getEventDate(_vm.schedule.schedule).date,"hour":_vm.getEventDate(_vm.schedule.schedule).hour,"schedule_id":_vm.$route.params.id},on:{"cancel":_vm.closeModal}})],1)],1)]},proxy:true},{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_id == 1 ? 'Sin Usuario': item.user.email)+" ")]}},{key:"item.tickets",fn:function(ref){
var item = ref.item;
return _vm._l((item.tickets),function(ticket){return _c('v-row',{key:ticket.id},[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(_vm._s(ticket.code))]),_c('v-col',{attrs:{"cols":"6"}},[_vm._v(_vm._s(ticket.used ? 'Usado': 'Sin Utilizar'))])],1)})}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }